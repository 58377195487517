import React from 'react'
import { graphql, Link } from 'gatsby'
import loadable from '@loadable/component'
import _ from 'lodash'
import Layout from '../../components/StructureComponents/Layout'
import Header from '../../components/shared/Header'
import FooterSection from '../../components/PageComponents/FooterSection'
import ThemeChanger from '../../components/shared/ThemeChanger'
import { Helmet } from 'react-helmet'
import Content from '../../components/StructureComponents/Content'
import Slider from 'react-slick/lib/slider'
import ThemeImageLoader from '../../components/shared/ThemeImageLoader'

export const customSubmitFunction = (path) => {
  if (!path || path === '') {
    return ''
  }
  try {
    if (typeof document !== 'undefined') {
      const anchor = document.createElement('a')
      const filename = path.replace(/^.*[\\\/]/, '')
      anchor.href = path
      anchor.download = filename

      document.body.appendChild(anchor)

      anchor.click()

      document.body.removeChild(anchor)
    }
  } catch (ex) {
    // console.log('Error is -->', ex)
  }
}

export const EbookIndexTemplate = ({
  slug,
  heroSection,
  productivitySection,
  ebookFeatures,
  introSection,
  downloadSection,
  testimonials
}) => {
  const ContactForm = loadable(() =>
    import('../../components/PageComponents/ContactForm/ContactForm')
  )

  const handleDownload = (formData) => {
    const selectedFormat = formData.fields.find(
      (f) => f.name === 'format'
    )?.value

    let downloadPath = ''
    const formats = heroSection?.formats || {}

    switch (selectedFormat) {
      case 'ebook':
        downloadPath = formats.ebook
        break
      case 'pdf':
        downloadPath = formats.pdf
        break
      case 'slideshow':
        downloadPath = formats.slideshow
        break
      default:
        downloadPath = formats.ebook
    }

    if (downloadPath) {
      customSubmitFunction(
        downloadPath.replace('/static', ''),
        _.kebabCase(slug)
      )
    }
  }

  const renderHeroSection = () => {
    return (
      <div className="hero-content" id="hero-section">
        <div className="hero-content_left">
          <img className="" src={heroSection?.featuredImage?.image} alt="" />
        </div>
        <div className="hero-content_right">
          <h1 className="main_heading">{heroSection.title}</h1>
          <p>{heroSection?.description}</p>
          <div className="ebook-form">
            <ContactForm
              fullForm={false}
              title={'Get It Now'}
              id={`${_.kebabCase(slug)}`}
              formId={`${_.kebabCase(slug)}`}
              customSubmitFunction={handleDownload}
            />
          </div>
        </div>
      </div>
    )
  }

  const renderProductivitySection = () => {
    return (
      <div className="productivity-section">
        <div className="productivity-inner-wrapper">
          <img
            className="rightDotsImg"
            src={'/images/rightDotsGroup.png'}
            alt="dotsImage"
            width={102}
            height={102}
          />
          <h2 className="productivity-title">
            {productivitySection.bannerTitle}
          </h2>
          <img
            className="leftDotsImg"
            src={'/images/leftDotsGroup.png'}
            alt="dotsImage"
            width={102}
            height={102}
          />
        </div>
        <div className="productivity-title-section">
          <span>{productivitySection.subtitle}</span>
          <div className="productivity-title-inner">
            <h3 className="productivity-title">{productivitySection.title}</h3>
            <p className="productivity-sub-title">
              {productivitySection.description}
            </p>
          </div>
        </div>
      </div>
    )
  }

  const renderEbookFeatures = () => {
    return (
      <div className="ebookFeatures-section">
        <h2 className="ebookFeatures-title">{ebookFeatures.title}</h2>
        <div className="ebookFeatures-box-list">
          {ebookFeatures?.features?.map((feature) => (
            <div className="ebookFeatures-box-list-inner">
              <img
                className="ebookFeatures-image"
                src={feature?.icon?.image}
                alt={feature?.icon?.alt}
              />
              <div className="ebookFeatures-box-about">
                <Content
                  source={feature.title}
                  className="ebookFeatures-box-title"
                />
                <p className="ebookFeatures-box-desc">{feature.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    )
  }

  const renderIntro = () => {
    return (
      <div className="intro-section">
        <img
          className="intro-top-bg"
          src={'/images/intro-bg-image.png'}
          alt="image"
        />
        <div className="intro-left">
          <Content
            source={introSection.description && introSection.description}
            className="intro-description"
          />
        </div>
        <div className="intro-right">
          <img
            className="intro-right-image"
            src={introSection?.introImage?.image}
            alt=""
          />

          <img
            className="intro-bottom-bg"
            src={'/images/introBottomBg.png'}
            alt="image"
          />
        </div>
      </div>
    )
  }

  const renderDownloadSection = () => {
    return (
      <div className="download-section">
        <img
          className="download-right-image"
          src={'/images/download-right-image.png'}
          alt="image"
        />
        <div className="download-wrapper">
          <h2 className="download-title">{downloadSection.title}</h2>
          <div className="download-bottom-btn">
            <Link href="#hero-section">
              <button className="e-book-btn">
                {downloadSection.buttonText}
              </button>
            </Link>
          </div>
        </div>
        <img
          className="download-left-image"
          src={'/images/download-left-image.png'}
          alt="image"
        />
      </div>
    )
  }

  const renderTestimonials = () => {
    let sliderRef = null
    const CustomPrevArrow = (props) => {
      const { className, onClick } = props
      return (
        <div className={`${className}`} onClick={onClick}>
          <img src="../../assets/images/left-arrow.svg" alt="" />
        </div>
      )
    }

    const CustomNextArrow = (props) => {
      const { className, onClick } = props
      return (
        <div className={`${className}`} onClick={onClick}>
          <img src="../../assets/images/right-arrow.svg" alt="" />
        </div>
      )
    }

    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: false,
      autoplaySpeed: 3000,
      arrows: true,
      adaptiveHeight: true,
      prevArrow: <CustomPrevArrow />,
      nextArrow: <CustomNextArrow />
    }
    return (
      <section className="testimonials-section">
        <div className="testimonials-left">
          <Content src={testimonials.title} className="testimonials-heading" />
          <p className="testimonials-description">{testimonials.description}</p>

          <div className="testimonials-navigation">
            <button className="nav-prev" onClick={() => sliderRef.slickPrev()}>
              <ThemeImageLoader
                imgName="left-arrow"
                imgExtention="svg"
                isIcon={true}
                customClass="heroSocialBar__socialIcon"
              />
            </button>
            <button className="nav-next" onClick={() => sliderRef.slickNext()}>
              <ThemeImageLoader
                imgName="right-arrow"
                imgExtention="svg"
                isIcon={true}
                customClass="heroSocialBar__socialIcon"
              />
            </button>
          </div>
        </div>

        <div className="testimonials-right">
          <Slider ref={(slider) => (sliderRef = slider)} {...settings}>
            {testimonials.testimonialsItems.map((testimonial, index) => (
              <div key={index} className="testimonial-slide">
                <img
                  className="quote-icon"
                  src={'/images/quote.png'}
                  alt="image"
                />

                <p className="testimonial-quote-text">{testimonial.quote}</p>
                <div className="testimonial-author">
                  <img
                    src={testimonial.authorImage.image}
                    alt={testimonial.authorImage.alt}
                    className="author-image"
                  />
                  <div className="author-details">
                    <h3 className="author-name">{testimonial.authorName}</h3>
                    <p className="author-role">{testimonial.authorRole}</p>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </section>
    )
  }

  return (
    <main className="ebookPage">
      <section className="ebookPage__hero">
        <div className="container">{renderHeroSection()}</div>
      </section>
      <section className="ebookPage__productivity">
        <div className="container">{renderProductivitySection()}</div>
      </section>
      <section className="ebookPage__features">
        <div className="container">{renderEbookFeatures()}</div>
      </section>
      <section className="ebookPage__intro">
        <div className="container">{renderIntro()}</div>
      </section>
      <section className="ebookPage__download">
        <div className="container">{renderDownloadSection()}</div>
      </section>
      <section className="ebookPage__testimonials">
        <div className="container">{renderTestimonials()}</div>
      </section>
    </main>
  )
}

const EbookIndex = ({ data: { page, indexPage } }) => {
  return (
    <Layout
      meta={page.frontmatter.meta || false}
      title={page?.frontmatter?.title || false}
    >
      <Helmet>
        <meta name={`robots`} content={`noindex, nofollow`} />
        <meta name={`googlebot`} content={`noindex, nofollow`} />
      </Helmet>

      <Header />
      <ThemeChanger />
      <EbookIndexTemplate {...page} {...page.frontmatter} />
      <FooterSection {...indexPage.frontmatter.footersection} />
    </Layout>
  )
}

export default EbookIndex

export const pageQuery = graphql`
  query EbookIndex($id: String!) {
    indexPage: markdownRemark(frontmatter: { template: { eq: "HomePage" } }) {
      frontmatter {
        footersection {
          primaryHeading
          description
          secondaryheading
          tertiaryheading
          footerList {
            primaryheading
            footerLink
          }
        }
        subscribeSection {
          title
          description
          secondaryButton {
            buttonText
            buttonLink
          }
        }
      }
    }

    page: markdownRemark(id: { eq: $id }) {
      ...Meta
      fields {
        slug
      }
      frontmatter {
        slug
        heroSection {
          featuredImage {
            image
          }
          title
          description
          formats {
            ebook
            pdf
            slideshow
          }
        }
        productivitySection {
          bannerTitle
          subtitle
          title
          description
        }
        ebookFeatures {
          title
          features {
            icon {
              image
              alt
            }
            title
            description
          }
        }
        introSection {
          description
          introImage {
            image
          }
        }
        downloadSection {
          title
          buttonText
        }
        testimonials {
          title
          description
          testimonialsItems {
            quote
            authorImage {
              image
              alt
            }
            authorName
            authorRole
          }
        }
      }
    }
  }
`
